import React, {
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useMeasure, useWindowSize } from "react-use";
import ThemeContext from "../../contexts/themeContext";
import Button from "../../components/bootstrap/Button";
import Portal from "../Portal/Portal";
import useDarkMode from "../../hooks/useDarkMode";
import { OverlayContext } from "../../contexts/OverlayContext";
import Icon from "../../components/icon/Icon";
import { useTranslation } from 'react-i18next';
import { MatterportContext } from "../../contexts/MatterportContext";
import modelsServices from "../../services/modelsServices";
import matterportHelper from "../../helpers/matterportHelper";
import volumenServices from "../../services/volumesServices";
import { useLocation } from "react-router-dom";
import CurrentNodeActions from "../../components/CurrentNodeActions";
import Dropdown, {
  DropdownToggle,
  DropdownMenu,
} from "../../components/bootstrap/Dropdown";
interface IHeaderLeftProps {
  children: ReactNode;
  className?: string;
}
export const HeaderLeft: FC<IHeaderLeftProps> = ({ children, className }) => {
  return (
    <div className={classNames("header-left", "col-md", className)}>
      {children}
    </div>
  );
};
HeaderLeft.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
HeaderLeft.defaultProps = {
  className: undefined,
};

interface IHeaderRightProps {
  children: ReactNode;
  className?: string;
}
let siteName = localStorage.getItem("siteName");
export const HeaderRight: FC<IHeaderRightProps> = ({ children, className }) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const root = document.documentElement;
  root.style.setProperty("--header-right-height", `${height}px`);

  return (
    <div
      ref={ref}
      className={classNames("header-right", "col-md-auto", className)}
    >
      {children}
    </div>
  );
};
HeaderRight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
HeaderRight.defaultProps = {
  className: undefined,
};

interface IHeaderProps {
  children:
  | ReactElement<IHeaderLeftProps>
  | ReactElement<IHeaderRightProps>
  | ReactNode;
  hasLeftMobileMenu?: boolean;
  hasRightMobileMenu?: boolean;
}
const Header: FC<IHeaderProps> = ({
  children,
  hasLeftMobileMenu,
  hasRightMobileMenu,
}) => {
  const { themeStatus } = useDarkMode();
  const location = useLocation();
  const windowsWidth = useWindowSize().width;
  const [refMobileHeader, sizeMobileHeader] = useMeasure<HTMLDivElement>();
  const [refHeader, sizeHeader] = useMeasure<HTMLDivElement>();
  const hierarchyContainer = useRef<HTMLDivElement>(null);
  const detailsContainer = useRef<HTMLDivElement>(null);

  const root = document.documentElement;
  root.style.setProperty(
    "--mobile-header-height",
    `${sizeMobileHeader.height}px`
  );
  const { darkModeStatus } = useDarkMode();

  root.style.setProperty("--header-height", `${sizeHeader.height}px`);
  const {
    asideStatus,
    setAsideStatus,
    leftMenuStatus,
    setLeftMenuStatus,
    rightMenuStatus,
    setRightMenuStatus,
  } = useContext(ThemeContext);
  const {
    setOffCanvasOpen,
    setPositionEditObject,
    setActivePositionButtons,
    setSensorsIsActive,
    sensorsIsActive,
    setCurrentNodeSelected,
    currentNodeSelected,
    modalState,
    setModalState
  } = useContext(OverlayContext);

  useLayoutEffect(() => {
    if (
      (asideStatus || leftMenuStatus || rightMenuStatus) &&
      windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
    )
      document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  });
  interface MyCustomEventDetail {
    idParent: number;
  }

  const clickOnVolumeEventHandler = (event: any) => {

    const dataJson = localStorage.getItem('dataJson');
    console.log({ dataJson: JSON.parse(dataJson + '') })
    if (dataJson === "undefined" || dataJson === null) {
      const idParent = event.detail.idParent;
      console.log({ "CLICK ON NODE": idParent })
      setActivePositionButtons(false);
      setOffCanvasOpen(true);

      const eventVolumeDiv = document.getElementById("eventVolume");
      console.log({ eventVolumeDiv })
      eventVolumeDiv?.setAttribute("volumeId", idParent);
      const clickEvent: any = new CustomEvent<MyCustomEventDetail>("volumeEvent");
      clickEvent.idParent = idParent;
      setTimeout(() => {
        eventVolumeDiv?.dispatchEvent(clickEvent);
        console.log({ clickEvent })
      }, 2000);

    } else {
      const idParent = event.detail.idParent;
      console.log({ "CLICK ON NODE": idParent })
      setOffCanvasOpen(true);
      const eventVolumeDiv = document.getElementById("eventVolume");
      console.log({ eventVolumeDiv })
      eventVolumeDiv?.setAttribute("volumeId", idParent);
      const clickEvent: any = new CustomEvent<MyCustomEventDetail>("volumeEvent");
      clickEvent.idParent = idParent;
      setTimeout(() => {
        eventVolumeDiv?.dispatchEvent(clickEvent);
        console.log({ clickEvent })
      }, 2000);
    }
  };

  function toogleDropButton(value: boolean) {
    document.getElementById("toogleDropMenu")?.click();
    console.log("CLICK javascript")
  };


  async function cargarobjectos(array: any) {

    console.log({ array })

    //console.log({ element })
    for (let i = 0; i < array.length; i++) {




      console.log({ modelList })
      const foundModel = modelList.find((model: any) => model.id === array[i].modelOID);

      const existModel = matterportHelper.checkIfModelExistByName(fbxObjects, currentNodeSelected._id + "/" + array[i].modelName)
      if (!existModel) {

        const data: any = await matterportHelper.loadModelMongo(matterportSdk, {
          mongoName: currentNodeSelected._id + "/" + array[i].modelName,
          animated: 0,
          id: i,
          interactuable: array[i].interactionType,
          length: 1,
          interactuable_content: array[i].interactionReference,
          interactuable_type: "text",
          modelfilename: foundModel ? foundModel.name : array[i].modelFileName,
          modelformat: array[i].modelFile.substring(array[i].modelFile.lastIndexOf(".") + 1),
          parent: currentNodeSelected._id,
          positionx: array[i].objectTransform.xPosition,
          positiony: array[i].objectTransform.yPosition,
          positionz: array[i].objectTransform.zPosition,
          rotationx: array[i].objectTransform.xRotation,
          rotationy: array[i].objectTransform.yRotation,
          rotationz: array[i].objectTransform.zRotation,
          scalex: array[i].objectTransform.xScale,
          scaley: array[i].objectTransform.yScale,
          scalez: array[i].objectTransform.zScale

        })
        data.name = currentNodeSelected._id + "/" + array[i].modelName;
        console.log({ fbxObjects });
        setFbxObjects((current: any) => [...current, { data }]);
        //  i++;
      }

    }


  }
  async function LoadModelsById(id: string) {
    await new Promise((resolve) => {
      modelsServices.getModelsByParentUUID(id).then(res => {
        if (res.models.length > 0 && matterportSdk !== undefined) {
          cargarobjectos(res.models);


          //  setFbxObjects(current => [...current, { data }]);
        }
      }).catch(err => {
        console.error(err)
        resolve({ err });
      })
    })
  }
  async function LoadVolumesById(id: any) {
    const role = localStorage.getItem("role");
    await new Promise((resolve) => {
      volumenServices.getVolumesByParentUUID(id).then(res => {
        if (res.volumens.length > 0 && matterportSdk !== undefined) {
          console.log({ volumes: res })
          for (let i = 0; i < res.volumens.length; i++) {

            const existModel = matterportHelper.checkIfVolumenExistByName(currentVolumes, res.volumens[i].volumeName + "/" + res.volumens[i].parentId);
            //   console.log({ volumeName: res.volumens[i].volumen.volumeName + "/" + res.volumens[i].parentId, existModel })
            if (!existModel) {
              setTimeout(() => {
                if (matterportSdk !== undefined && res.volumens[i].objectTransform !== "") {
                  matterportHelper.emptyModelLoader({
                    animated: 0,
                    id: i,
                    interactuable: res.volumens[i].interactionType,
                    length: 1,
                    interactuable_content: res.volumens[i].interactionReference,
                    interactuable_type: "text",
                    modelfilename: "CuboTransparente.fbx",
                    modelformat: "fbx",
                    parent: currentNodeSelected._id,
                    positionx: res.volumens[i].objectTransform.xPosition,
                    positiony: res.volumens[i].objectTransform.yPosition,
                    positionz: res.volumens[i].objectTransform.zPosition,
                    rotationx: res.volumens[i].objectTransform.xRotation,
                    rotationy: res.volumens[i].objectTransform.yRotation,
                    rotationz: res.volumens[i].objectTransform.zRotation,
                    scalex: res.volumens[i].objectTransform.xScale,
                    scaley: res.volumens[i].objectTransform.yScale,
                    scalez: res.volumens[i].objectTransform.zScale

                  }, matterportSdk, role)
                    .then((data) => {
                      data.name = currentNodeSelected._id + "/" + res.volumens[i].volumeName
                      setCurrentVolumes((current: any) => [...current, { data: { node: data } }]);
                      //     i++;
                    })
                    .catch((err) => {
                      console.error(err);
                      //  i++;
                    });
                }
              }, 1500);
            } else {
              // i++;
            }



          }

          //  setFbxObjects(current => [...current, { data }]);
        }
      }).catch(err => {
        console.error(err)
        resolve({ err });
      })
    })

  }
  const { t } = useTranslation('menu');
  const {
    fbxObjects,
    currentVolumes,
    setCurrentVolumes,
    matterportSdk,
    setFbxObjects,
    modelList,
    site,
  } = useContext(MatterportContext);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [buttonSelection, setButtonSelection] = useState({
    isActiveModels: false,
    isActiveSensors: false,
    isActiveVolumes: false,
  });
  const [mounted, setMounted] = useState(false);
  const [space, setSpace] = useState<string | null>(null);
  const [modelsInitialLoad, setModelsInitialLoad] = useState(true);
  const [volumesInitialLoad, setVolumesInitialLoad] = useState(true);
  const [rootPath, setRootPath] = useState(false);
  const [userPath, setUserPath] = useState(false);

  const nodeEventHandler = (event: any) => {
    console.log({ nodeHandler: event.detail, currentNodeSelected })
    // Do something with the event data
    if (event.detail !== undefined) {
      setCurrentNodeSelected(event.detail)
    }
  };
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      window.addEventListener("hierarchyNodeEvent", nodeEventHandler);
      setSpace(localStorage.getItem("space"))
    }
  }, [mounted]);
  useEffect(() => {

    window.addEventListener("clickOnVolume", clickOnVolumeEventHandler);

  }, [])
  useEffect(() => {
    //console.log(`La ruta ha cambiado a ${location.pathname}`);
    if (location.pathname === "/") {
      setRootPath(true);
    } else {
      setRootPath(false);
    }
    if (location.pathname === "/ManageUsers") {
      setUserPath(true)
    } else {
      setUserPath(false)
    }
  }, [location]);

  return (
    <>
      <header ref={refMobileHeader} className="mobile-header">
        {currentNodeSelected._id !== null && <CurrentNodeActions
          isMobil={true}
        />}
        <div className="container-fluid">

          <div className="row">
            <div className="col">

              <Button
                aria-label="Toggle Aside"
                className="mobile-header-toggle"
                size="lg"
                color={asideStatus ? "primary" : themeStatus}
                isLight={asideStatus}
                icon={asideStatus ? "FirstPage" : "LastPage"}
                onClick={() => {
                  setAsideStatus(!asideStatus);
                  setLeftMenuStatus(false);
                  setRightMenuStatus(false);
                }}
              />
              {
                !rootPath && site !== undefined &&
                <div id="siteTitle" className="siteTitle center-absolute-horizontal">

                  <h3>
                    {`${site !== "" ? site : siteName}`}

                  </h3>
                  <small className="siteTitle" >{currentNodeSelected?.name}</small>
                </div>
              }
              {hasLeftMobileMenu && (
                <div
                  aria-label="Toggle Left Menu"
                  className={`btn mobile-header-toggle ${rootPath || userPath ? 'hidden' : ''} `}
                  color={leftMenuStatus ? "primary" : themeStatus}
                >

                  <Dropdown className="pointer-events"
                    isOpen={dropdownIsOpen}
                  >

                    <DropdownToggle hasIcon={false}
                      isOpen={dropdownIsOpen}
                      setIsOpen={() => toogleDropButton(dropdownIsOpen)}

                    >
                      <Button
                        id="toogleDropMenu"
                        className="btn btn-light btn-lg btn-only-icon mobile-header-toggle"
                        icon={leftMenuStatus ? "MoreVert" : "MoreHoriz"}
                        aria-label="Quick menu"

                        onClick={() => setDropdownIsOpen(dropdownIsOpen)}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      isAlignmentEnd
                      size="lg"
                      className="py-0 overflow-hidden"
                    >
                      <div className="row g-0">
                        <div
                          className={classNames(
                            "col-12",
                            "p-4",
                            "d-flex justify-content-center",
                            "fw-bold fs-5",
                            "text-info",
                            "border-bottom border-info",
                            {
                              "bg-l25-info": !darkModeStatus,
                              "bg-lo25-info": darkModeStatus,
                            }
                          )}
                        >
                          Acciones
                        </div>

                        <div
                          className="col-6 p-4 transition-base cursor-pointer bg-light-hover"
                          onClick={() => {
                            hierarchyContainer.current?.classList.remove(
                              "hidden"
                            );
                            detailsContainer.current?.classList.add("hidden");
                            setOffCanvasOpen(true);
                            setPositionEditObject((prev: any) => ({
                              ...prev, // Copiar todas las propiedades existentes
                              active: false, // Actualizar solo esta propiedad
                            }));
                            setActivePositionButtons(false);
                            setDropdownIsOpen(false);
                            toogleDropButton(dropdownIsOpen)
                          }}
                        >
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <Icon icon="segment" size="3x" color="warning" />
                            <span>Jerarquía</span>
                          </div>
                        </div>

                        <div
                          className={classNames(
                            "col-6 p-4 transition-base cursor-pointer bg-light-hover",
                            { "border-dark": darkModeStatus }
                          )}

                          onClick={() => {
                            console.log({ dropdownIsOpen })
                            setDropdownIsOpen(false);
                            toogleDropButton(false);

                            setButtonSelection((prev) => ({
                              ...prev, // Copiar todas las propiedades existentes
                              isActiveModels: !buttonSelection.isActiveModels// Actualizar solo esta propiedad
                            }));
                            console.log({ mounted, matterportSdk, space, modelsInitialLoad, fbxObjects, button: buttonSelection.isActiveModels })
                            if (buttonSelection.isActiveModels === false) {
                              console.log({ fxbObjectsM: fbxObjects })
                              if (mounted && matterportSdk !== undefined && space !== undefined) {
                                console.log("a load")
                                if (modelsInitialLoad === true) {
                                  setModelsInitialLoad(false);
                                  if (currentNodeSelected !== undefined) {
                                    LoadModelsById(currentNodeSelected._id)
                                      .catch(err => {
                                        console.log({ err })
                                      })
                                  } else {

                                  }

                                } else {
                                  matterportHelper.turnOnAllCurrentFbxObjects(fbxObjects)
                                }
                              }
                            } else if (fbxObjects !== undefined) {
                              matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);
                            }
                          }
                            //toogleDropButton(false)

                          }

                        >
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <Icon icon="View_in_ar" size="3x" color="info" />
                            <span> {t("Models")}</span>
                          </div>
                        </div>

                        <div
                          className="col-6 p-4 transition-base cursor-pointer bg-light-hover"
                          onClick={() => {
                            //setEditMode(false);
                            //setType("sensor");
                            //setActiveSaveButton(false);
                            //setActivePositionButtons(false);
                            setDropdownIsOpen(false);
                            toogleDropButton(dropdownIsOpen)


                          }}
                        >
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <Icon icon="Sensors" size="3x" color="warning" />
                            <span> {t("Sensors")}</span>
                          </div>
                        </div>

                        <div
                          className="col-6 p-4 transition-base cursor-pointer bg-light-hover"
                          onClick={() => {
                            //  setEditMode(false);
                            //setType("volume");
                            //setActiveSaveButton(false);
                            //setActivePositionButtons(false);
                            setDropdownIsOpen(false);
                            toogleDropButton(dropdownIsOpen);
                            setSensorsIsActive(!sensorsIsActive)
                            setButtonSelection((prev) => ({
                              ...prev, // Copiar todas las propiedades existentes
                              isActiveSensors: !buttonSelection.isActiveSensors// Actualizar solo esta propiedad
                            }));
                          }}
                        >
                          <div className="d-flex flex-column align-items-center justify-content-center"
                            onClick={() => {
                              setDropdownIsOpen(false);
                              toogleDropButton(dropdownIsOpen)
                              setButtonSelection((prev) => ({
                                ...prev, // Copiar todas las propiedades existentes
                                isActiveVolumes: !buttonSelection.isActiveVolumes// Actualizar solo esta propiedad
                              }));

                              if (currentVolumes !== undefined && buttonSelection.isActiveVolumes === false) {
                                if (mounted && matterportSdk !== undefined && space !== undefined) {
                                  if (volumesInitialLoad === true  /*buttonSelection.isActiveVolumes*/) {
                                    setVolumesInitialLoad(false);
                                    LoadVolumesById(currentNodeSelected._id)
                                      .catch(err => {
                                        console.error({ err })
                                      })
                                    /*
                                                                        LoadVolumesById(space)
                                                                        .catch(err => {
                                                                            console.err({ err })
                                                                        })*/
                                  } else {
                                    console.log({
                                      currentVolumes
                                    })
                                    matterportHelper.turnOnAllCurrentVolumens(currentVolumes)
                                  }
                                }
                              } else if (currentVolumes !== undefined) {
                                matterportHelper.turnOffAllCurrentVolumens(currentVolumes);
                              }
                            }}
                          >
                            <Icon
                              icon="border_clear"
                              size="3x"
                              color="warning"
                            />
                            <span> {t("Volumes")}</span>

                          </div>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            </div>
            {hasRightMobileMenu && (
              <div className="col-auto">
                <Button
                  aria-label="Toggle Right Menu"
                  className="mobile-header-toggle"
                  size="lg"
                  color={rightMenuStatus ? "primary" : themeStatus}
                  isLight={rightMenuStatus}
                  icon={rightMenuStatus ? "Menu" : "MenuOpen"}
                  onClick={() => {
                    setAsideStatus(false);
                    setLeftMenuStatus(false);
                    setRightMenuStatus(!rightMenuStatus);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </header>
      <header
        ref={refHeader}
        className={classNames("header", {
          "header-left-open": leftMenuStatus,
          "header-right-open": rightMenuStatus,
        })}
      >
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            {children}
            {(leftMenuStatus || rightMenuStatus) && (
              <Portal>
                <div
                  role="presentation"
                  className={classNames("header-overlay", {
                    "header-overlay-left-menu": leftMenuStatus,
                    "header-overlay-right-menu": rightMenuStatus,
                  })}
                  onClick={() => {
                    setAsideStatus(false);
                    setLeftMenuStatus(false);
                    setRightMenuStatus(false);
                  }}
                />
              </Portal>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
Header.propTypes = {
  children: PropTypes.node.isRequired,
  hasLeftMobileMenu: PropTypes.bool,
  hasRightMobileMenu: PropTypes.bool,
};
Header.defaultProps = {
  hasLeftMobileMenu: true,
  hasRightMobileMenu: true,
};

export default Header;
