import React, { useContext, useState, useEffect, useRef } from 'react'

import FormGroup from '../bootstrap/forms/FormGroup';
import Label from '../bootstrap/forms/Label';
import Input from '../bootstrap/forms/Input';

import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';

import { OverlayContext } from '../../contexts/OverlayContext';
import { currentPose } from '../../helpers/matterportHelper';
import Button, { ButtonGroup } from '../bootstrap/Button';
import hierarchyHelper from '../../helpers/hierarchyHelper';
import ParentChanger from './ParentChanger';
import hierachyServices from '../../services/hierachyServices';
import { useTranslation } from "react-i18next";


function EditHierarchy(props) {
    const { rootNodeOption } = props;
    const [hierachyData, setHierachyData] = useState({})
    const [firstRender, setFirstRender] = useState(true)
    const [lastIndex, setLasIndex] = useState(0);
    const [editFather, setEditFather] = useState(false)
    const [addNewChild, setAddNewChild] = useState(false)
    const [newChildName, setnewChildName] = useState("Nuevo hijo");
    const [currentParent, setCurrentParent] = useState("");
    const [childrenList, setChildrenList] = useState({})
    const [activeUpdate, setActiveUpdate] = useState(false)
    const itemList = useRef();
    const {
        setModalState,
        modalState,
        currentNodeSelected,
        setOffCanvasOpen,
        setToolbarIsOpen,
        setUpdateHierarchy,
        setReturnView,
        returnView,


    } = useContext(OverlayContext);

    const [newName, setNewName] = useState(currentNodeSelected.name);
    const closeEdit = () => {
        setEditFather(false);
    };
    const updateCurrentParent = (parent) => {
        setCurrentParent(parent)
    };

    const { t } = useTranslation('menu');

    const addNewHierachyChild = (idParent, name, spaceId) => {
        if (!rootNodeOption) {
            hierachyServices.addNewChildren(idParent, name, spaceId)
                .then(res => {
                    if (res.ok) {
                        setAddNewChild(false);

                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("NewElement"),
                                text: t("NewElementSavedCorrectly"),
                                id: "newHierarchy",
                                actionType: "new",
                            },
                        });
                        updateChildrenList();
                        hierarchyHelper.restartHierarchyData();
                        setUpdateHierarchy(true);
                    }
                })
                .catch((err) => {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("Error"),
                            text: t("AlreadyExistElement"),
                            id: "errorModel",
                            actionType: "error",
                        },
                    });
                    console.error(err)
                })
        } else {
            hierachyServices.addNewChildren(spaceId, name, spaceId)
                .then(res => {
                    if (res.ok) {
                        setAddNewChild(false);

                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("NewElement"),
                                text: t("NewElementSavedCorrectly"),
                                id: "newHierarchy",
                                actionType: "new",
                            },
                        });
                        updateChildrenList();
                        hierarchyHelper.restartHierarchyData();
                        setUpdateHierarchy(true);
                    }
                })
                .catch((err) => {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("Error"),
                            text: t("AlreadyExistElement"),
                            id: "errorModel",
                            actionType: "error",
                        },
                    });
                    console.error(err)
                })
        }
    };
    const updateChildrenList = () => {
        console.log({ updateChildren: currentNodeSelected })

        if (currentNodeSelected.root !== undefined) {
            hierachyServices.getChildren(currentNodeSelected.root._id)
                .then(res => {
                    if (res.ok) {
                        setChildrenList({ children: res.children })

                    }
                })
                .catch(err => console.error({ err }))
        } else if (currentNodeSelected.parentOID !== undefined) {
            hierachyServices.getChildren(rootNodeOption ? currentNodeSelected.parentOID : currentNodeSelected._id)
                .then(res => {
                    if (res.ok) {
                        console.log({ childList: res.children })
                        setChildrenList({ children: res.children })

                    }
                })
                .catch(err => console.error({ err }))

        }
    }

    useEffect(() => {
        if (modalState.continueClicked && modalState.data.actionType === "delete") {
            hierarchyHelper.restartHierarchyData();
            hierachyServices.deleteHierarchyElement(modalState.data.id)
                .then(res => {
                    if (res.ok) {
                        setOffCanvasOpen(true);
                        setAddNewChild(false);
                        setToolbarIsOpen(false);
                        setUpdateHierarchy(true);
                    }
                }).catch(err => console.error({ err }))
            setModalState({
                ...modalState,
                continueClicked: false,
            });

        }
    }, [modalState])
    useEffect(() => {
        if (addNewChild || editFather) {
            setnewChildName("")
            setReturnView(true);
        } else {
            setReturnView(false);
        }
    }, [addNewChild, editFather])


    useEffect(() => {
        if (!returnView) {
            setAddNewChild(false);
            setEditFather(false);
        }
    }, [returnView])

    useEffect(() => {
        if (currentNodeSelected !== null /*&& editFather === false*/) {
            updateChildrenList();

        }
        if (currentNodeSelected !== null) {
            setNewName(currentNodeSelected.name);
        }
    }, [currentNodeSelected])

    useEffect(() => {
        if (Object.keys(hierachyData).length === 0 && firstRender) {
            setFirstRender(false)

            hierachyServices.getQueryBySpace(localStorage.getItem("space"))
                .then(res => {
                    setHierachyData(res)
                })
                .catch((err) => console.error(err))
            updateChildrenList();
        }
    }, [])
    return (
        <div className="">
            {addNewChild ?
                <>

                    <Label>{t("NewChild")} </Label>

                    <Input
                        ariaLabel="Input"
                        className=''
                        name="newChildName"
                        value={newChildName}
                        className=''
                        onChange={(e) => {
                            setnewChildName(
                                e.target.value
                            )
                        }}
                    ></Input>

                    <ButtonGroup
                        className='mt-3'
                    >
                        <Button
                            color="secondary"
                            icon="Save"
                            id='saveNewChild'
                            isLight
                            onClick={() => {
                                const space = localStorage.getItem("space")
                                if (space !== undefined) {

                                    addNewHierachyChild(currentNodeSelected._id, newChildName, space)
                                }

                            }}
                            isDisable={newChildName === ""}
                        >
                            {t("Save")}
                        </Button>

                    </ButtonGroup>
                </>
                : <>
                    {editFather ?
                        <ParentChanger
                            hierachyData={hierachyData}
                            updateCurrentParent={updateCurrentParent}
                            closeEdit={closeEdit}
                        ></ParentChanger>
                        : <>
                            <FormGroup>
                                <Label
                                    className='md-3 '
                                    size="lg">
                                    {t("CurrentName")}:
                                </Label>

                                <Input
                                    ariaLabel="Input"

                                    name="newName"
                                    value={newName}
                                    placeholder={t("Name")}
                                    className=''
                                    onChange={(e) => {
                                        if (currentNodeSelected.name === e.target.value) {
                                            setActiveUpdate(false)
                                        } else {
                                            setActiveUpdate(true)
                                        }
                                        setNewName(
                                            e.target.value
                                        )
                                    }}

                                ></Input>

                                <Button
                                    color="info"
                                    icon="change_cirlce"
                                    isLight
                                    className={`mt-3 ${activeUpdate ? '' : 'hidden'}`}

                                    onClick={() => {
                                        hierachyServices.updateName(currentNodeSelected._id, newName)
                                            .then(res => {
                                                if (res.ok) {
                                                    localStorage.removeItem("dataJson");
                                                    setUpdateHierarchy(true);
                                                    setModalState({
                                                        ...modalState,
                                                        isOpen: !modalState.isOpen,
                                                        data: {
                                                            title: t("UpdateTitle"),
                                                            text: t("UpdateNameCorrectly"),
                                                            id: "updateName",
                                                            actionType: "update",
                                                        },
                                                    });
                                                }
                                            })
                                            .catch(error => {
                                                console.log({ error })

                                                if (error.response.data.ok === false) {

                                                    let errorMsg = error.response.data.msg === "NameExist" ? t("AlreadyExistElement") : t("UnkownError");

                                                    setModalState({
                                                        ...modalState,
                                                        isOpen: !modalState.isOpen,
                                                        data: {
                                                            title: "Error",
                                                            text: errorMsg,
                                                            id: "errorModel",
                                                            actionType: "error",
                                                        },
                                                    });
                                                }
                                            })
                                    }}
                                >
                                    {t("UpdateName")}
                                </Button>
                                <br></br>
                                <Label
                                    className=''
                                    size="lg">
                                    {t("Children")}
                                </Label>
                            </FormGroup>
                            <ListGroup >
                                {childrenList.children?.length > 0 ?
                                    childrenList.children.map((child, index) => (
                                        <ListGroupItem
                                            key={index + child.name}
                                            className='pointer-hand no-pointer-events'
                                            isDisable
                                        >
                                            {child.name}
                                        </ListGroupItem>
                                    ))
                                    :
                                    <ListGroupItem
                                        isDisable
                                    >
                                        {t("NotChildrenFound")}
                                    </ListGroupItem>
                                }

                            </ListGroup>

                            <ButtonGroup
                                className='mt-3 center center-content'
                                id='hierarchyButtonGroup'
                            >
                                <Button
                                    color="info"
                                    icon="upgrade"
                                    className={`${rootNodeOption ? 'hidden' : ''}`}
                                    isLight
                                    onClick={() => {
                                        setReturnView(true);
                                        setEditFather(true)
                                    }}
                                >
                                    <span>
                                        {t("ChangeParent")}
                                    </span>
                                </Button>
                                <Button

                                    color="info"
                                    icon="playlist_add"
                                    isLight
                                    onClick={() => {

                                        setAddNewChild(true)
                                    }}
                                >
                                    <span>
                                        {t("NewChild")}
                                    </span>
                                </Button>
                                <Button
                                    color="info"
                                    icon="camera_indoor"
                                    isLight
                                    className={`${rootNodeOption ? 'hidden' : ''}`}
                                    onClick={() => {
                                        setReturnView(true);
                                        console.log({ SAvecurrentPose: currentPose })

                                        hierachyServices.updateCamera(
                                            currentNodeSelected._id,
                                            currentPose.sweep ? currentPose.sweep : currentPose.uuid,

                                            currentPose.rotation.x,
                                            currentPose.rotation.y

                                        )
                                            .then(res => { console.log({ res }) })
                                            .catch(err => console.log({ err }))

                                        hierarchyHelper.restartHierarchyData();
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            data: {
                                                title: t("UpdateTitle"),
                                                text: t("UpdatedCamera"),
                                                id: "updateCamera",
                                                actionType: "update",
                                            },
                                        });

                                    }}
                                >
                                    <span>
                                        {t("UpdateCamera")}
                                    </span>
                                </Button>
                                <Button
                                    color="danger"
                                    icon="Delete"
                                    className={`${rootNodeOption ? 'hidden' : ''}`}
                                    isLight
                                    onClick={() => {
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            continueClicked: false,
                                            data: {
                                                title: t("Attention"),
                                                text: `${t("TextDeleteHierarchyPart1")} ${currentNodeSelected.name}.
                                                    ${t("TextDeleteHierarchyPart2")}`,
                                                id: currentNodeSelected._id,
                                                actionType: "delete",
                                                elementName: currentNodeSelected.name
                                            },
                                        });
                                    }}
                                >
                                    <span>
                                        {t("Delete")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </>
                    }
                </>
            }



        </div>
    )
}

export default EditHierarchy