import React, { useState, useContext, useEffect, useRef } from 'react'

import FormGroup from '../bootstrap/forms/FormGroup'
import Label from '../bootstrap/forms/Label'
import Input from '../bootstrap/forms/Input'
import FormText from '../bootstrap/forms/FormText'
import volumenServices from '../../services/volumesServices'
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup'
import Button from '../bootstrap/Button'
import { OverlayContext } from '../../contexts/OverlayContext'
import EditableListItem from './EditableListItem'
import Select from '../bootstrap/forms/Select'
import matterportHelper, { currentPose } from '../../helpers/matterportHelper'
import { useTranslation } from "react-i18next";
import { MatterportContext } from '../../contexts/MatterportContext'
import { defaultModelId } from '../../helpers/matterportHelper'
function VolumeProperty() {
    const [volumens, setVolumens] = useState([{
        "volumeName": "",
        "interactionType": "goToDocumentData",
        "interactionReference": "",
        "objectTransform": {
            "xPosition": 0.01,
            "yPosition": 0.01,
            "zPosition": 0.01,
            "xRotation": 0.01,
            "yRotation": 0.01,
            "zRotation": 0.01,
            "xScale": 0.01,
            "yScale": 0.01,
            "zScale": 0.01
        },
        "sweepCameraPosition": {
            "sweepointUUID": "texto",
            "cameraHorizontalRotation": 0.01,
            "cameraVerticalRotation": 0.01
        }
    }]);
    const [addNewVolume, setAddNewVolume] = useState(false);
    const [nameForm, setNameForm] = useState("")
    const [descriptionForm, setDescriptionForm] = useState("")
    const [selectedValue, setSelectedValue] = useState('goToDocumentData');
    const [lastIndex, setLasIndex] = useState(0);


    const initialVolume = {
        volumeName
            : "",
        interactionType: "goToDocumentData",
        interactionReference: "",
        objectTransform: {
            xPosition: 0,
            yPosition: 0,
            zPosition: 0,
            xRotation: 0,
            yRotation: 0,
            zRotation: 0,
            xScale: 0,
            yScale: 0,
            zScale: 0,

        },
        sweepCameraPosition: {
            sweepointUUID: "text",
            cameraHorizontalRotation: 0.1,
            cameraVerticalRotation: 0.1,
        },
        previousName: "",
        previousReference: ""

    }

    const itemList = useRef();
    const {
        editMode,
        setEditMode,
        setActiveSaveButton,
        setReturnView,
        setActivePositionButtons,
        setPositionEditObject,
        positionEditObject,
        currentNodeSelected,
        isSaveClicked,
        setModalState,
        modalState,
        volumeData,
        setVolumeData,
        setUpdateHierarchy,
        setEditModel
    } = useContext(OverlayContext);
    const {
        fbxObjects,
        currentEditObject,
        matterportSdk,
        setCurrentVolumes,
        currentVolumes
    } = useContext(MatterportContext);

    const { t } = useTranslation('menu');
    function reloadVolume(modelOID, sdk, initialModel, newData) {

        console.log({ modelOID, initialModel, newData })
        initialModel = {
            ...initialModel,
            positionx: newData.objectTransform.xPosition,
            positiony: newData.objectTransform.yPosition,
            positionz: newData.objectTransform.zPosition,
            rotationx: newData.objectTransform.xRotation,
            rotationy: newData.objectTransform.yRotation,
            rotationz: newData.objectTransform.zRotation,
            scalex: newData.objectTransform.xScale,
            scaley: newData.objectTransform.yScale,
            scalez: newData.objectTransform.zScale,
            interactuable_content: newData.interactionReference,
            interactuable_type: newData.interactionType,
            modelFilename: newData.modelFileName,
            parent: newData.parent,
            interactuable: newData.interactionType
        }
        console.log({ fbxObjects })
        matterportHelper.deleteVolumeById(currentVolumes, initialModel.mongoName).then(res => {
            console.log({ volumesRes: res })
            setCurrentVolumes(res);
        });
        matterportHelper.dispatchCustomEvent("delete", { msg: "Borrado" })
        matterportHelper.loadModelMongoOID(sdk,
            initialModel
            , true
        )
            .then((data) => {
                if (data !== undefined) {
                    data.isEdit = false;
                    console.log({ fbxObjects })
                    setCurrentVolumes(current => [...current, { data: data }]);
                    matterportHelper.borrarElementosConMismoIdExceptoUltimo(currentVolumes, initialModel.mongoName)
                }
                //  setUpdateHierarchy(true);
                /*
                setEditModel({
                    model: data,
                    control: res

                });
                */
            })
            .catch((err) => {
                console.error(err);

            });
    }

    const getElementData = (data) => {
        console.log({ data })
        console.log({ positionEditObject })
        setVolumeData({
            ...volumeData,
            volumeName: data.name,
            previousName: data.name,
            previousReference: data.interactionReference,
            objectTransform: data.position,
            sweepCameraPosition: data.camera,
            interactionType: data.interactionType,
            interactionReference: data.interactionReference
        })

        setPositionEditObject(prev => ({
            ...prev, // Copiar todas las propiedades existentes
            editsweepCameraPosition: data.sweepCameraPosition,
            initialModelData: {
                mongoName: currentNodeSelected._id + "/" + data.name,
                animated: 0,
                id: "",
                interactuable: data.interactionType,
                length: 1,
                interactuable_content: "zxczxc",
                interactuable_type: "text",
                modelFilename: "65efcc40da75f1b8cbc01756",
                modelformat: "fbx",
                parent: "",
                OID: "65efcc40da75f1b8cbc01756",
                positionx: data.position.xPosition,
                positiony: data.position.yPosition,
                positionz: data.position.zPosition,
                rotationx: data.position.xRotation,
                rotationy: data.position.yRotation,
                rotationz: data.position.zRotation,
                scalex: data.position.xScale,
                scaley: data.position.yScale,
                scalez: data.position.zScale,
                originalOID: "65efcc40da75f1b8cbc01756"
            },
            isNew: false
        }));
    }


    const deleteByName = (name) => {
        volumenServices.deleteVolumen(currentNodeSelected._id, name)
            .then(res => {
                setEditMode(false)
                if (res.ok === true) {
                    setActiveSaveButton(false);
                    updateVolumensToShow();
                }
            }
            )
            .catch(err => console.error(err));
    }

    const updateVolumensToShow = () => {
        console.log("Volume to show");
        volumenServices.getVolumesByParentUUID(currentNodeSelected._id).then(res => {
            if (res.ok === true) {
                console.log({ volumensData: res })

                setVolumens(res.volumens)
                setLasIndex(res.lastIndex);
            }
        }).catch((err) => console.error({ err }))
    }

    function notExistsSameName(id, volumeName) {
        console.log("CHECK EXIST")
        return new Promise((resolve, reject) => {
            volumenServices.checkName(id, volumeName).then(res => {
                if (res.ok === true && res.existName === false) {
                    console.log("CHECK EXIST A")
                    resolve(true)
                } else if (res.ok === true && res.existName === true) {
                    console.log("CHECK EXIST B")
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("Error"),
                            text: t("AlreadyExistElement"),
                            id: "errSensor",
                            actionType: "error",
                        },
                    });
                    reject(false);
                }
            }).catch(err => {
                console.log("CHECK EXIST C")
                console.error({ err })
                reject(false)
            });
        });

    }

    function getNewVolumeData(volumeName) {
        const newVolumeData = volumens.find(element => element.volumeName === volumeName);
        return newVolumeData;
    }

    const moveToPoint = (data) => {
        console.log({ data })
        let sweepCorrect;
        if (data.sweepCameraPosition.sweepointUUID.includes("-")) {
            sweepCorrect = data.sweepCameraPosition.sweepointUUID.replace(/-/g, "");
        } else {

            sweepCorrect = data.sweepCameraPosition.sweepointUUID;
        }

        matterportHelper.onMovePoint(matterportSdk, {
            sweep: sweepCorrect, rotation: {
                x: data.sweepCameraPosition.cameraHorizontalRotation,
                y: data.sweepCameraPosition.cameraVerticalRotation,
            }
        })

    }
    async function LoadSpaceVolumes(space) {
        const role = localStorage.getItem("role");

        console.log({ currentVolumes });
        const newVolumes = [];

        await new Promise((resolve) => {
            volumenServices
                .getVolumesBySpace(space)
                .then((res) => {
                    if (res.volumens.length > 0 && matterportSdk !== undefined) {
                        console.log({ volumes: res });

                        // Procesa todos los volúmenes en paralelo con una lista de promesas
                        const promises = res.volumens.map((volume, i) => {
                            return new Promise((resolveVolume) => {
                                setTimeout(() => {
                                    if (matterportSdk !== undefined && volume.objectTransform !== "") {
                                        const volumeParent = volume.parentId;

                                        matterportHelper
                                            .emptyModelLoader(
                                                {
                                                    animated: 0,
                                                    id: i,
                                                    interactuable: volume.volumen.interactionType,
                                                    length: 1,
                                                    interactuable_content: volume.volumen.interactionReference,
                                                    interactuable_type: "text",
                                                    modelfilename: "CuboTransparente.fbx",
                                                    modelformat: "fbx",
                                                    parent: volumeParent,
                                                    positionx: volume.volumen.objectTransform.xPosition,
                                                    positiony: volume.volumen.objectTransform.yPosition,
                                                    positionz: volume.volumen.objectTransform.zPosition,
                                                    rotationx: volume.volumen.objectTransform.xRotation,
                                                    rotationy: volume.volumen.objectTransform.yRotation,
                                                    rotationz: volume.volumen.objectTransform.zRotation,
                                                    scalex: volume.volumen.objectTransform.xScale,
                                                    scaley: volume.volumen.objectTransform.yScale,
                                                    scalez: volume.volumen.objectTransform.zScale,
                                                },
                                                matterportSdk,
                                                role
                                            )
                                            .then((data) => {
                                                data.name = `${volumeParent}/${volume.volumen.volumeName}`;
                                                newVolumes.push({ data: { node: data } });
                                                resolveVolume(); // Resuelve esta promesa individual
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                resolveVolume(); // Resuelve incluso si hay un error
                                            });
                                    } else {
                                        resolveVolume(); // Resuelve si no hay transformación
                                    }
                                }, 1500);
                            });
                        });

                        // Espera a que todas las promesas terminen
                        Promise.all(promises).then(() => {
                            resolve(); // Resuelve la promesa principal
                        });
                    } else {
                        resolve(); // Resuelve si no hay volúmenes o `matterportSdk` no está definido
                    }
                })
                .catch((err) => {
                    console.error(err);
                    resolve(); // Resuelve en caso de error
                });
        });

        // Devuelve el arreglo de nuevos volúmenes
        return newVolumes;
    }

    const changeCurrentVolumesStateToEmptyArray = () => {
        return new Promise((resolve) => {
            setCurrentVolumes([]);
            resolve();
        });
    };
    useEffect(() => {
        if (
            (volumeData.volumeName !== "" && /*!addNewVolume*/(volumeData.previousName !== volumeData.volumeName)) ||
            volumeData.interactionReference != "" &&  /*!addNewVolume*/(volumeData.previousReference !== volumeData.interactionReference)
        ) {
            // setActiveSaveButton(true);
        }
    }, [volumeData]);

    useEffect(() => {
        if (editMode) {
            setReturnView(true);
        }
    }, [editMode])
    useEffect(() => {
        if (currentNodeSelected !== null) {

            updateVolumensToShow()
        }
    }, [currentNodeSelected])
    useEffect(() => {
        /*
        console.log({ "volumenPosition": positionEditObject })
        if (positionEditObject.editPosition !== undefined) {
            setVolumeData({
                ...volumeData, objectTransform: {
                    xPosition: positionEditObject.editPosition.position.x,
                    yPosition: positionEditObject.editPosition.position.y,
                    zPosition: positionEditObject.editPosition.position.z,
                    xRotation: positionEditObject.editPosition.rotation._x,
                    yRotation: positionEditObject.editPosition.rotation._y,
                    zRotation: positionEditObject.editPosition.rotation._z,
                    xScale: positionEditObject.editPosition.scale.x,
                    yScale: positionEditObject.editPosition.scale.y,
                    zScale: positionEditObject.editPosition.scale.z
                }
                , sweepCameraPosition: {
                    sweepointUUID: positionEditObject.editsweepCameraPosition.sweepointUUID,
                    cameraHorizontalRotation: positionEditObject.editsweepCameraPosition.cameraHorizontalRotation,
                    cameraVerticalRotation: positionEditObject.editsweepCameraPosition.cameraVerticalRotation
                },
            })
        }
        */

        if (positionEditObject.editPosition !== undefined) {
            console.log({ positionEditObject, currentPose })
            setVolumeData({
                ...volumeData, objectTransform: {
                    xPosition: positionEditObject.editPosition.position.x,
                    yPosition: positionEditObject.editPosition.position.y,
                    zPosition: positionEditObject.editPosition.position.z,
                    xRotation: positionEditObject.editPosition.rotation._x,
                    yRotation: positionEditObject.editPosition.rotation._y,
                    zRotation: positionEditObject.editPosition.rotation._z,
                    xScale: positionEditObject.editPosition.scale ? positionEditObject.editPosition.scale.x : 1,
                    yScale: positionEditObject.editPosition.scale ? positionEditObject.editPosition.scale.y : 1,
                    zScale: positionEditObject.editPosition.scale ? positionEditObject.editPosition.scale.z : 1
                }, sweepCameraPosition: {
                    sweepointUUID: positionEditObject.editsweepCameraPosition.sweepointUUID,
                    cameraHorizontalRotation: positionEditObject.editsweepCameraPosition.cameraHorizontalRotation,
                    cameraVerticalRotation: positionEditObject.editsweepCameraPosition.cameraVerticalRotation
                },
                modelOID: positionEditObject.modelOID
            })
        }
    }, [positionEditObject])
    useEffect(() => {
        if (isSaveClicked) {
            console.log({ savedFbx: fbxObjects })
            console.log({ volumeData })
            volumeData.sweepCameraPosition = {

                sweepointUUID: volumeData.sweepCameraPosition.sweepointUUID,
                cameraHorizontalRotation: currentPose.rotation.x,
                cameraVerticalRotation: currentPose.rotation.y
            }

            if (!addNewVolume) {
                volumenServices.uppdateVolumeByParentId(
                    currentNodeSelected._id,
                    volumeData,
                    volumeData.previousName
                )
                    .then(res => {
                        console.log({ UpdateREs: res })
                        console.log({ currentEditObject, positionEditObject })
                        console.log({ volumeData })

                        /*                                   
                        volumeData.parent = currentNodeSelected._id;
                        positionEditObject.initialModelData.scalex = volumeData.objectTransform.xScale;
                        positionEditObject.initialModelData.scaley = volumeData.objectTransform.yScale;
                        positionEditObject.initialModelData.scalez = volumeData.objectTransform.zScale;
                        positionEditObject.initialModelData.rotationx = volumeData.objectTransform.xRotation;
                        positionEditObject.initialModelData.rotationy = volumeData.objectTransform.yRotation;
                        positionEditObject.initialModelData.rotationz = volumeData.objectTransform.zRotation;
                        */

                        setReturnView(false);
                        setEditMode(false)
                        setActiveSaveButton(false);
                        //         matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);

                        console.log({ currentEditObject })
                        /*currentEditObject.control?.nodeControl.stop();
                        currentEditObject.model?.node.stop();
                        */

                        reloadVolume(positionEditObject.initialModelData.OID, matterportSdk, positionEditObject.initialModelData, volumeData)
                        /*
                        if (matterportHelper.checkIfModelExistByName(currentVolumes, positionEditObject.initialModelData.mongoName)) {
                        }*/
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("UpdateTitle"),
                                text: t("UpdatedElementCorrectly"),
                                id: "newVolume",
                                actionType: "edit",
                            },
                        });

                    })
                    .catch((err) => {
                        console.error(err)
                        if (!err.response.data.ok) {
                            if (err.response.data.msg == "Already exist name") {
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("Error"),
                                        text: t("AlreadyExistElement"),
                                        id: "errorVolume",
                                        actionType: "error",
                                    },
                                });
                            }
                        }
                    })
            } else if (isSaveClicked && addNewVolume) {
                notExistsSameName(currentNodeSelected._id, volumeData.volumeName).then((res) => {
                    if (res === true && !addNewVolume) {


                    } else {
                        volumenServices.addNewVolume(
                            currentNodeSelected._id,
                            {
                                ...volumeData,
                                objectTransform: {
                                    "xPosition": currentEditObject.model.node.obj3D.position.x,
                                    "yPosition": currentEditObject.model.node.obj3D.position.y,
                                    "zPosition": currentEditObject.model.node.obj3D.position.z,
                                    "xScale": currentEditObject.model.node.obj3D.scale.x,
                                    "yScale": currentEditObject.model.node.obj3D.scale.y,
                                    "zScale": currentEditObject.model.node.obj3D.scale.z,
                                    "xRotation": currentEditObject.model.node.obj3D.rotation.x,
                                    "yRotation": currentEditObject.model.node.obj3D.rotation.y,
                                    "zRotation": currentEditObject.model.node.obj3D.rotation.z,
                                }
                            }
                        )
                            .then(res => {
                                setEditMode(false)
                                setActiveSaveButton(false);
                                //  matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);
                                console.log({ currentEditObject })
                                console.log({ volumeData })
                                //   currentEditObject.control?.nodeControl.stop();
                                //  currentEditObject.model?.node.stop();

                                /**Hacer algo aqui */
                                matterportHelper.deleteAllSceneObjects(currentVolumes).then(() => {
                                    // setVolumesInitialLoad(true)
                                    changeCurrentVolumesStateToEmptyArray().then(() => {
                                        const space = localStorage.getItem("space");
                                        LoadSpaceVolumes(space)
                                            .then((res) => {
                                                setCurrentVolumes(res)
                                                updateVolumensToShow();
                                            });

                                    })

                                });
                                /*
                               
                                LoadSpaceVolumes(space)
                                    .catch(err => {
                                        console.err({ err })
                                    })
                                    */
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("NewElement"),
                                        text: t("NewElementSavedCorrectly"),
                                        id: "newSensor",
                                        actionType: "new",
                                    },
                                });
                            })
                            .catch((err) => console.error(err))
                    }

                })
            }


        }
    }, [isSaveClicked, addNewVolume])
    useEffect(() => {
        console.log({ volumeData })
        setVolumeData(initialVolume)

    }, [])

    useEffect(() => {
        console.log({ currentVolumes })
    }, [currentVolumes]);
    return (

        <>
            {editMode ? <FormGroup>
                <Label htmlFor="volumeName">
                    {t("VolumeName")}
                </Label>
                <Input
                    ariaDescribedby="volume name"
                    id="volumeName"
                    placeholder="Volume name"
                    className=''
                    value={volumeData.volumeName}
                    onChange={(e) => {
                        setVolumeData({ ...volumeData, volumeName: e.target.value })
                        setPositionEditObject(prev => ({
                            ...prev, // Copiar todas las propiedades existentes
                            name: e.target.value, // Actualizar solo esta propiedad
                            type: "volume"
                        }));
                        setActiveSaveButton(true);
                    }
                    }
                />
                <Label htmlFor="selectType">
                    {t("Type")}
                </Label>
                <Select
                    ariaLabel='Type'
                    id="selectType"

                    list={[
                        {
                            text: t("goToDocumentData"),
                            value: "goToDocumentData"
                        },
                        /*
                        {
                            text: t("displayText"),
                            value: "displayText"
                        },
                        */
                        {
                            text: t("openURL"),
                            value: "openURL"
                        }
                    ]}
                    onChange={(e) => {
                        setVolumeData({ ...volumeData, interactionType: e.target.value })
                        setActiveSaveButton(true);
                        if (e.target.value === "goToDocumentData") {
                        }
                    }
                    }
                    title="Type"
                    value={volumeData.interactionType}
                />
                {volumeData.interactionType === "openURL" || volumeData.interactionType === "displayText" ?
                    <>
                        <Label htmlFor="volumeName">
                            {t("text")}
                        </Label>
                        <Input
                            ariaDescribedby="Inptu text"
                            id="volumeInteractionReference"
                            placeholder="Text "
                            className=''
                            value={volumeData.interactionReference}
                            onChange={(e) => {
                                setVolumeData({ ...volumeData, interactionReference: e.target.value })
                                setPositionEditObject(prev => ({
                                    ...prev, // Copiar todas las propiedades existentes
                                    name: e.target.value, // Actualizar solo esta propiedad
                                    type: "volume"
                                }));
                            }
                            }
                        />
                    </>
                    : <></>
                }


                <Label htmlFor="editPostion">
                    {t("EditPosition")}
                </Label>
                <br />
                <Button
                    id="editPostion"
                    className=' '
                    color="secondary"
                    onClick={() => {

                        console.log({ positionEditObject, currentPose, volumeData })

                        setActivePositionButtons(true)
                        if (positionEditObject.isNew
                        ) {
                            setPositionEditObject(prev => ({
                                ...prev, // Copiar todas las propiedades existentes
                                active: true, // Actualizar solo esta propiedad
                                name: volumeData.voumeName,
                                type: "volume",
                                fileName: "default",
                                isNew: addNewVolume,
                                initialModelData: {

                                    ...positionEditObject.initialModelData,
                                    positionx: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).x,
                                    positiony: currentPose.position.y,

                                    positionz: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).z,

                                    rotationx: 0,
                                    rotationy: 0,

                                    rotationz: 0,

                                    scalex: 1,

                                    scaley: 1,

                                    scalez: 1,

                                    OID: defaultModelId,
                                    animated: 0,
                                    id: "",
                                    interactuable: volumeData.interactionType,
                                    interactuable_content: volumeData.interactionReference,
                                    interactuable_type: volumeData.interactionType,
                                    length: 1,

                                    modelformat: "fbx",
                                    mongoName: currentNodeSelected._id + "/" + volumeData.volumeName,
                                    originalOID: defaultModelId
                                },
                                editPosition: {
                                    rotation: {
                                        x: volumeData.objectTransform.xRotation,
                                        y: volumeData.objectTransform.yRotation,
                                        z: volumeData.objectTransform.zRotation,
                                    },
                                    position: {
                                        x: volumeData.objectTransform.xPosition,
                                        y: volumeData.objectTransform.yPosition,
                                        z: volumeData.objectTransform.zPosition,

                                    },
                                    scale: {
                                        x: volumeData.objectTransform.xScale,
                                        y: volumeData.objectTransform.yScale,
                                        z: volumeData.objectTransform.zScale,

                                    }
                                },

                                editsweepCameraPosition: volumeData.sweepCameraPosition
                            }));
                            setActiveSaveButton(true);
                        } else {
                            console.log({ volumeData })
                            console.log({ volumens })
                            const newVolumeData = getNewVolumeData(volumeData.volumeName)
                            console.log({ newVolumeData })
                            /*
                            setVolumeData(prev => ({
                                ...prev,
    
                            }))
                            */
                            setPositionEditObject(prev => ({
                                ...prev, // Copiar todas las propiedades existentes
                                active: true,
                                name: volumeData.voumeName,
                                type: "volume",
                                editPosition: {

                                    position: {
                                        x: newVolumeData.objectTransform.xPosition,
                                        y: newVolumeData.objectTransform.yPosition,
                                        z: newVolumeData.objectTransform.zPosition,
                                    },
                                    rotation: {
                                        _x: newVolumeData.objectTransform.xRotation,
                                        _y: newVolumeData.objectTransform.yRotation,
                                        _z: newVolumeData.objectTransform.zRotation,
                                    },

                                    scale: {
                                        x: newVolumeData.objectTransform.xScale,
                                        y: newVolumeData.objectTransform.yScale,
                                        z: newVolumeData.objectTransform.zScale,
                                    }

                                }

                            }));
                            setActiveSaveButton(true);
                        }
                    }}
                    icon="edit_location"
                >{t("EditPosition")}</Button>

            </FormGroup>
                :
                <>
                    <ListGroup
                        className="mb-3"
                        ref={itemList}
                    >

                        {volumens.map((volumens, index) => (
                            <EditableListItem
                                key={index + volumens.volumeName}

                                name={volumens.volumeName}
                                camera={volumens.sweepCameraPosition}
                                position={volumens.objectTransform}
                                parentOID={volumens.parentId}
                                interactionType={volumens.interactionType}
                                stateChanger={setEditMode}
                                getElementData={getElementData}
                                deleteAction={deleteByName}
                                setStateNotNewVolume={setAddNewVolume}
                                sweepCameraPosition={volumens.sweepCameraPosition}
                                interactionReference={volumens.interactionReference}
                                moveToPoint={moveToPoint}
                            >

                            </EditableListItem>
                        ))}
                    </ListGroup>
                    <div className='display-flex'>
                        <Button
                            className='grow-0'
                            color="primary"
                            onClick={() => {
                                console.log({ volumeData })
                                setAddNewVolume(true);
                                if (itemList.current.children.length > 0) {
                                    itemList.current.children[itemList.current.children.length - 1].scrollIntoView();
                                }
                                setEditMode(true)
                                setPositionEditObject(prev => ({
                                    ...prev, // Copiar todas las propiedades existentes
                                    name: volumeData.volumeName, // Actualizar solo esta propiedad
                                    fileName: "cube.fbx",
                                    position: volumeData.objectTransform,
                                    type: "volume",
                                    isNew: true,
                                }));

                                setVolumeData({
                                    ...volumeData,
                                    volumeName: "Volume " + (Number(lastIndex) + 1),
                                    modelFileName: t("noSelectedModel"),
                                    sweepCameraPosition: {
                                        sweepointUUID: currentPose.sweep,
                                        cameraHorizontalRotation: currentPose.rotation.y,
                                        cameraVerticalRotation: currentPose.rotation.x,
                                    }
                                })
                            }
                            }
                            icon="add"
                        >{t("New")}</Button>
                        <div className='grow-1'></div>
                    </div>

                </>
            }
        </>
    )
}

export default VolumeProperty