import React, { useEffect, useRef } from 'react';

import '../styles/components/_adjustableSensor.scss'






const AdjustableSensor = ({ src }) => {
    const iframeWrapperRef = useRef(null);
    const iframeRef = useRef(null);

    useEffect(() => {
        const adjustIframeScale = () => {
            const iframeWrapper = iframeWrapperRef.current;
            const iframe = iframeRef.current;

            if (!iframeWrapper || !iframe) return;

            // Tamaño original del contenido del iframe
            const originalWidth = 800;
            const originalHeight = 600;

            // Dimensiones actuales del contenedor
            const wrapperWidth = iframeWrapper.offsetWidth;
            const wrapperHeight = iframeWrapper.offsetHeight;

            // Calcular las escalas para ajustar el contenido
            const scaleWidth = wrapperWidth / originalWidth;
            const scaleHeight = wrapperHeight / originalHeight;

            // Usar la escala más pequeña para garantizar que todo el contenido sea visible
            const scale = Math.min(scaleWidth, scaleHeight);

            // Aplicar la escala al iframe
            iframe.style.transform = `scale(${scale})`;

            // Ajustar el tamaño del contenedor del iframe para evitar desbordamiento
            iframe.style.width = `${originalWidth}px`;
            iframe.style.height = `${originalHeight}px`;
        };

        // Ajustar la escala al cargar y al cambiar el tamaño de la ventana
        adjustIframeScale();
        window.addEventListener('resize', adjustIframeScale);

        // Cleanup
        return () => window.removeEventListener('resize', adjustIframeScale);
    }, []);

    return (
        <div

            className="iframe-wrapper pointer-events"
            onDoubleClick={() => window.open(src, '_blank')}
            ref={iframeWrapperRef}
            style={{ height: '200px' /* Ajusta según tus necesidades */ }}

        >
            <iframe
                className='pointer-events'
                id="responsive-iframe"
                src={src}
                ref={iframeRef}
                onDoubleClick={() => window.open(src, '_blank')}

            />
        </div>
    );
};

export default AdjustableSensor;