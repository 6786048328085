import React, { useContext, useState, useEffect } from 'react'

import './../../styles/components/matterportUi/Toolbar.scss'
import Button, { ButtonGroup } from '../bootstrap/Button'
import PropertiesPanel from './PropertiesPanel'
import { OverlayContext } from '../../contexts/OverlayContext'
import SaveBar from './SaveBar'
import matterportHelper from '../../helpers/matterportHelper'
import ContainerOptionTools from './containerOptionTools';
import Card, { CardHeader, CardTitle, CardActions, CardBody } from '../bootstrap/Card'
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from "../../components/bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ThemeContext from '../../contexts/themeContext'

function Toolbar(props) {
    const { first } = { ...props };
    const {
        setToolbarIsOpen,
        offCanvasOpen,
        currentNodeSelected,
        setActiveSaveButton, activePositionButtons,
        setEditMode, editMode,
        returnView, setReturnView,
        setActivePositionButtons,
        positionEditObject,
        modalState, setModalState,
        hierarchyRootOption,
        addModelFile, setAddModelFile,
        setEditModel
    } = useContext(OverlayContext);

    const { hierarchyLock } = useContext(ThemeContext)

    const [type, setType] = useState(first);
    const [isMobile, setIsMobile] = useState(false);
    const mediaQuery = 1452 * 720;
    const { t } = useTranslation('menu');

    function detectZoom() {
        var zoomLevel = Math.round(window.devicePixelRatio * 100);

        if (zoomLevel <= 150) {
            // Se ha hecho un zoom del 150%
            setIsMobile(true);

            // Puedes realizar acciones específicas o llamar a funciones aquí
        }
    }

    const handleResize = () => {
        const resolution = window.innerHeight * window.innerWidth;
        setIsMobile(resolution <= mediaQuery);
        //setIsMobile(window.innerWidth <= 1200); // Puedes ajustar el valor según tus necesidades
    };



    // Volver a detectar el zoom cuando se redimensiona la ventana
    window.addEventListener('resize', handleResize);
    useEffect(() => {
        detectZoom();
        handleResize();


    }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

    useEffect(() => {
        console.log({ currentNodeSelected })
    }, [currentNodeSelected])

    useEffect(() => {
        console.log({ modalState })
    }, [modalState])
    useEffect(() => {
        setActiveSaveButton(false);
        setReturnView(false)
    }, [type]);


    return (
        <div id="toolbarContainer"
            className={`${offCanvasOpen ? 'hierachy-is-open' : 'hierachy-is-closed'} ${activePositionButtons ? 'hidden' : ''} ${hierarchyLock ? 'toolbarContainer-hierarchy-lock' : ''}`}
        >
            {isMobile ? <ContainerOptionTools editMode={editMode} hierarchyRootOption={hierarchyRootOption} isMobile={isMobile} type={type} setEditMode={setEditMode} setActiveSaveButton={setActiveSaveButton} setActivePositionButtons={setActivePositionButtons} setType={setType}

                activePositionButtons={activePositionButtons}

            /> : <></>}
            <Modal
                id="ModalMessage"
                isOpen={modalState?.isOpen}
                setIsOpen={setModalState}
                titleId="example-title"
            >
                <ModalHeader

                    ModalTitle="titulo"
                    children="title"
                >
                    <ModalTitle id="example-title"
                        children="title"
                    >
                        {modalState.data?.title}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>{modalState.data?.text}</ModalBody>
                <ModalFooter>
                    <ButtonGroup isToolbar>
                        <Button
                            className={
                                modalState.data?.actionType === "delete" ||
                                    modalState.data?.actionType === "move"
                                    ? "" : "hidden"}
                            color="light"
                            onClick={() => {
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                });
                            }}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            color="light"
                            onClick={() => {

                                switch (modalState.data.actionType) {
                                    case "delete":
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            continueClicked: true,
                                        });

                                        break;
                                    case "edit":
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            continueClicked: true,
                                        });

                                        break;
                                    case "new":
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                        });
                                        break;
                                    case "move":
                                        setModalState({

                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            continueClicked: true,
                                        });
                                    case "update":
                                        setModalState({

                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                            continueClicked: true,
                                        });
                                        break;
                                    case "error":
                                        setModalState({
                                            ...modalState,
                                            isOpen: !modalState.isOpen,
                                        });
                                        break;
                                    default:
                                        break;
                                }

                            }}
                        >
                            {t("Continue")}
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>



            <Card
                className={`${positionEditObject.active ? 'hidden' : ''}
                 ${hierarchyLock ? 'toolbarCard-hierarchy-lock' : ''}
                `}
                id="toolbarCard"
            >
                <CardHeader>
                    <CardTitle
                        className='w-80'
                    >
                        <span
                            className='ellipsis-text'
                        >
                            {currentNodeSelected?.name}{' '}
                        </span>

                    </CardTitle>
                    {/**
                         * <CardSubTitle>
                            Card Subtitle
                        </CardSubTitle>

                         * 
                         */}
                    <CardActions>

                        {
                            !returnView ? <></> :
                                <Button
                                    color="secondary"
                                    icon="Arrow_back"
                                    onClick={() => {

                                        if (addModelFile === true) {
                                            setAddModelFile(false)

                                        } else {
                                            setActiveSaveButton(false)
                                            setEditMode(false)
                                            setReturnView(false)
                                            setActivePositionButtons(false)
                                        }
                                    }}
                                ></Button>
                        }

                        <Button
                            color="danger"
                            hoverShadow="default"
                            icon="Close"
                            isLight
                            onClick={() => {
                                setActiveSaveButton(false)
                                setEditMode(false)
                                setReturnView(false)
                                setActivePositionButtons(false)
                                setToolbarIsOpen(false)
                                setActivePositionButtons(false)
                                matterportHelper.dispatchCustomEvent("delete", { msg: "Borrado" })
                                setEditModel(undefined)

                            }}
                        >
                        </Button>
                    </CardActions>
                </CardHeader>
                <CardBody
                    id='bodyPanel'
                >
                    <PropertiesPanel
                        type={hierarchyRootOption ? "rootNode" : type}
                        currentNodeSelected={currentNodeSelected}
                    ></PropertiesPanel>
                    <SaveBar ></SaveBar>
                    {!isMobile ? <ContainerOptionTools editMode={editMode} hierarchyRootOption={hierarchyRootOption} isMobile={isMobile} type={type} setEditMode={setEditMode} setActiveSaveButton={setActiveSaveButton} setActivePositionButtons={setActivePositionButtons} setType={setType} /> : <></>}

                </CardBody>

            </Card>

        </div>
    )
}

export default Toolbar