import React, { useContext, useEffect } from 'react'

import { ListGroupItem } from '../bootstrap/ListGroup'
import Button from '../bootstrap/Button';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useTranslation } from "react-i18next";

function EditableListItem(props) {
    const { name, stateChanger, getElementData, camera, position, fileName, setStateNotNewVolume, deleteAction, url, interactionType, modelFileName, interactionReference,
        modelOID, sweepCameraPosition, parentOID, moveToPoint,
        notAciveMoveToPoint
    } = props;

    const {
        setModalState,
        modalState
    } = useContext(OverlayContext);

    const { t } = useTranslation('menu');

    useEffect(() => {
        if (modalState.continueClicked && modalState.data.actionType === "delete" && modalState.data.id === "deleteElement"
            && name == modalState.data.elementName
        ) {
            setModalState({
                ...modalState,
                continueClicked: false,
            });
            deleteAction(modalState.data.elementName);
        }
    }, [modalState])
    return (
        <ListGroupItem>
            <span>
                {name}
            </span>
            <div>
                {notAciveMoveToPoint === true ? <></> :
                    <Button
                        color="info"
                        isOutline
                        icon="Language"
                        size="sm"
                        onClick={() => {
                            // stateChanger(true);
                            moveToPoint({
                                camera,
                                sweepCameraPosition,
                            });
                            setStateNotNewVolume(false);
                            console.log({
                                sweepCameraPosition, name, stateChanger, getElementData, camera, position, fileName, setStateNotNewVolume, deleteAction, url, interactionType, modelFileName, interactionReference
                            })

                        }}
                    >
                    </Button>
                }


                <Button
                    color="info"
                    isOutline
                    icon="edit"
                    size="sm"
                    onClick={() => {
                        stateChanger(true);
                        getElementData({
                            camera,
                            position,
                            name,
                            url,
                            fileName,
                            interactionType,
                            modelFileName,
                            interactionReference,
                            modelOID,
                            sweepCameraPosition,
                            parentOID
                        });
                        setStateNotNewVolume(false);
                        console.log({
                            name, stateChanger, getElementData, camera, position, fileName, setStateNotNewVolume, deleteAction, url, interactionType, modelFileName, interactionReference
                        })
                    }}
                >
                </Button>
                <Button
                    color="danger"
                    isOutline
                    icon="delete"
                    size="sm"
                    onClick={() => {
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            continueClicked: false,
                            data: {
                                title: t("Delete"),
                                text: t("QuestionDeleteProperty"),
                                id: "deleteElement",
                                actionType: "delete",
                                elementName: name
                            },
                        });

                    }}
                >
                </Button>

            </div>
        </ListGroupItem>
    )
}

export default EditableListItem