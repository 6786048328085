import React, { useContext, useEffect, useState, useRef } from 'react'
import Popovers from './bootstrap/Popovers.tsx';
//import * as d3 from './../libs/d3.min.js'
import { Runtime, Inspector } from "@observablehq/runtime";

import { MatterportContext } from '../contexts/MatterportContext.tsx';
import define from "./../libs/indentTree.js";
import openNode from "./../libs/indentTree.js";
import matterportHelper from '../helpers/matterportHelper.js';
import Details from './Details.jsx';
import "../styles/components/_hierarchy.scss";
import { OverlayContext } from '../contexts/OverlayContext.tsx';
import hierachyServices from '../services/hierachyServices.ts';


function simularClick(id) {
  const div = document.getElementById(id);
  div.click();

}

const handleClick = (id) => {
  console.log('Div clicleado:', id);
};


function findParents(json, targetObj, parents = []) {
  if (typeof json !== 'object' || json === null) {
    return;
  }

  if (json === targetObj) {
    return parents;
  }

  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      let parent = findParents(json[key], targetObj, [...parents, key]);
      if (parent) {
        return parent;
      }
    }
  }

  return null;
}

const simulateClick = (elementId) => {
  openNode("asd")
  console.log("click " + elementId);
  console.log({ elementId });
  const element = document.getElementById(elementId.target.textContent);
  openNode("ASdasdzxc")
  if (element) {
    /*
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
  */
    const clickEvent = new Event("click", {
      bubbles: true,
      cancelable: true
    });
    element.dispatchEvent(clickEvent);
  }
}



//import d3 from d3;
function HierarchyTree(props) {
  const isCompoenetmountedHierachy = useRef(false);

  const { data, inputSearch, isOpen, refContainer } = props;
  console.log(data);
  const [mountedHierachy, setMountedHierachy] = useState(false);
  const [isLoadedSpaceStorage, setIsLoadedSpaceStorage] = useState(null);
  const [positionNode, setPositionNode] = useState();

  let localspace = localStorage.getItem("space");
  const {
    toolbarIsOpen,
    setToolbarIsOpen,
    setCurrentNodeSelected,
    setOffCanvasOpen,
    setPositionEditObject,
    detailsIsOpen,
    updateHierarchy,
    setUpdateHierarchy,
    offCanvasOpen,
    setDetailsIsOpen,
    setEditMode,
    setActiveSaveButton,
    setActivePositionButtons,
    setHierarchyRootOption,
    hierarchyEventsActive,
    setHierarchyEventsActive
  } = useContext(OverlayContext);
  const {
    matterportSdk,
    appState
  } = useContext(MatterportContext);

  const nodeOptionEventHandler = (event) => {
    console.log("nodeOption");
    setDetailsIsOpen(false);
    console.log({ nodeEvent: event })
    if (event.detail !== undefined) {
      setCurrentNodeSelected(event.detail)
    }

    if (event.detail.isRoot) {
      setHierarchyRootOption(true)
    } else {
      setHierarchyRootOption(false)
    }
    setToolbarIsOpen(false);
    setTimeout(() => {
      setToolbarIsOpen(true);
    }, 500);



    setOffCanvasOpen(false);
    setPositionEditObject(prev => ({
      ...prev, // Copiar todas las propiedades existentes
      active: false  // Actualizar solo esta propiedad
    }));


  };

  function agregarEventListenerSiNoExiste(evento, handler) {
    if (!window.hasEventListener(evento, handler)) {
      window.addEventListener(evento, handler);
    }
  }
  const positionEventHandler = (event) => {
    console.log("PÖSIRT");
    console.log({ matterportSdk })
    if (event.detail !== undefined) {
      setCurrentNodeSelected(event.detail)
    }
    hierachyServices.cameraPosition(event.detail._id)
      .then(res => {
        if (res.ok) {
          console.log({ res })
          if (res.cameraData.sweepointUUID !== null) {
            let sweepCorrect;
            if (res.cameraData.sweepointUUID.includes("-")) {
              sweepCorrect = res.cameraData.sweepointUUID.replace(/-/g, "");
            } else {

              sweepCorrect = res.cameraData.sweepointUUID;
            }
            matterportHelper.onMovePoint(matterportSdk, {
              sweep: sweepCorrect, rotation: {
                x: res.cameraData.cameraHorizontalRotation,
                y: res.cameraData.cameraVerticalRotation
              }
            })
          }
        }
      })
      .catch(err => console.error(err))
  };

  const clickOnVolumeEventHandler = (event) => {
    const idParent = event.detail.idParent;
    console.log({ "CLICK ON NODE": idParent })
    console.log({ eventCLICK: event.detail })

    setEditMode(false)
    setActiveSaveButton(false)
    setActivePositionButtons(false)
    setOffCanvasOpen(true)


    const eventVolumeDiv = document.getElementById("eventVolume");
    console.log({ eventVolumeDiv })
    eventVolumeDiv.setAttribute("volumeId", idParent);
    const clickEvent = new CustomEvent("volumeEvent", {
      idParent
    });

    setTimeout(() => {
      eventVolumeDiv.dispatchEvent(clickEvent);
    }, 1000);
  };

  const clickOnModelEventHandler = (event) => {

    const idParent = event.detail.idParent;
    console.log({ "CLICK ON NODE": idParent })
    console.log({ eventCLICK: event.detail })
    if (event.detail.content === ""
    ) {


      setEditMode(false)
      setActiveSaveButton(false)
      setActivePositionButtons(false)
      setOffCanvasOpen(true)


      const eventVolumeDiv = document.getElementById("eventVolume");
      console.log({ eventVolumeDiv })
      eventVolumeDiv.setAttribute("volumeId", idParent);
      const clickEvent = new CustomEvent("volumeEvent", {
        idParent
      });

      setTimeout(() => {
        eventVolumeDiv.dispatchEvent(clickEvent);
      }, 1000);
    }

  };
  function getAllEventListeners(element) {
    const listeners = getEventListeners(element);

    // Convertir el objeto de event listeners a un array para facilitar su manipulación
    const listenersArray = Object.keys(listeners).map(evento => {
      return {
        evento,
        listeners: listeners[evento]
      };
    });
    return listenersArray;
  }

  useEffect(() => {
    const localStorageValue = localStorage.getItem('space');

    window.addEventListener("nodeOptionEvent", nodeOptionEventHandler);

    if (localStorageValue !== null) {
      setIsLoadedSpaceStorage(true);
    }
  }, []);
  useEffect(() => {
    console.log({ appState })
    if (hierarchyEventsActive === false && appState === "appphase.playing") {
      setTimeout(() => {

        setHierarchyEventsActive(true)
      }, 10000);
    }
  }, [appState])

  const timer = setInterval(() => {
    // Si el valor no es null, salimos del bucle
    if (localspace !== null) {
      setIsLoadedSpaceStorage(true);
      clearInterval(timer);
    }
    localspace = localStorage.getItem("space");

  }, 1000);

  useEffect(() => {
    if (!mountedHierachy && isLoadedSpaceStorage) {
      console.log('Este es el primer renderizado del componente');
      setMountedHierachy(true);
      const runtime = new Runtime();
      const hierarchyContainer = document.getElementById("hierarchy-container");
      if (!hierarchyContainer.firstElementChild) {

        console.log({ children: hierarchyContainer.childNodes })

        for (let index = 0; index < hierarchyContainer.childNodes.length; index++) {
          const element = array[index];
          console.log({ element })

        }
        console.log({ define })
        const main = runtime.module(define, Inspector.into(document.getElementById("hierarchy-container")));
        console.log({ main })
        var myDiv = document.getElementById('offcanvas-body');
        if (myDiv) {
          myDiv.addEventListener('scroll', function () {
            if (myDiv.scrollTop === 0) {
              console.log('Has llegado hasta arriba del div');
              document.getElementById("auto-complete-container").classList.remove('hidden');
            } else if (myDiv.scrollTop > 100) {
              document.getElementById("auto-complete-container").classList.add('hidden');
            }
          });
        }

      }



    }
  }, [mountedHierachy, isLoadedSpaceStorage]);

  useEffect(() => {

    if (updateHierarchy && offCanvasOpen) {
      setTimeout(() => {
        const runtime = new Runtime();
        const hierarchyContainer = document.getElementById("hierarchy-container");
        console.log({ hierarchyContainer, type: typeof hierarchyContainer });
        if (hierarchyContainer) {
          hierarchyContainer.innerHTML = "";

          console.log("ACTUALIZAR")
          if (!hierarchyContainer.firstElementChild) {

            console.log({ children: hierarchyContainer.childNodes })
            for (let index = 0; index < hierarchyContainer.childNodes.length; index++) {
              const element = array[index];
              console.log({ element })
            }
            console.log({ define })
            const main = runtime.module(define, Inspector.into(document.getElementById("hierarchy-container")));
            console.log({ main })
            var myDiv = document.getElementById('offcanvas-body');
            if (myDiv) {

              myDiv.addEventListener('scroll', function () {
                if (myDiv.scrollTop === 0) {

                  console.log('Has llegado hasta arriba del div');
                  document.getElementById("auto-complete-container").classList.remove('hidden');
                } else if (myDiv.scrollTop > 100) {

                  document.getElementById("auto-complete-container").classList.add('hidden');
                }
              });
            }
          }
          setUpdateHierarchy(false)
        }
      }, 4000)
      setUpdateHierarchy(true)
    }
    if (!offCanvasOpen) {

    }
  }, [updateHierarchy, offCanvasOpen])
  useEffect(() => {
    console.log({ inputSearch })
    console.log({ dataSEARCH: data })
    if (inputSearch !== "" && (!Array.isArray(inputSearch))) {
      document.getElementById("auto-complete-container").classList.remove('hidden');
    } else {
      // document.getElementById("auto-complete-container").classList.add('hidden');
    }
  }, [data, inputSearch])
  //
  useEffect(() => {
    if (matterportSdk !== undefined && positionNode !== undefined) {
      matterportHelper.onMovePoint(matterportSdk, positionNode)
      console.log({ positionNode })
    }
  }, [positionNode])

  useEffect(() => {
    if (matterportSdk !== undefined) {
      window.addEventListener("clickOnVolume", clickOnVolumeEventHandler);
      window.addEventListener("clickOnModel", clickOnModelEventHandler);
      window.addEventListener("positionEvent", positionEventHandler);
    }
  }, [matterportSdk])

  return (
    <div
      id="hierachyTree"
      ref={refContainer}
      className={` ${isOpen ? "" : "hidden"} container`}
    >


      <div className='form-control card hidden '
        id="auto-complete-container"
      >


        {Array.isArray(data) && data.length > 0 ?

          data.map((element, index) => (
            element._id ?
              <div
                key={index + "element"}
                id={element._id}
                desc={element.name}
                trigger="hover"
                className='pointer-hand suggest-item fs-7 mb-1 mp-1'>
                {element.name}
              </div>
              : <></>
          ))
          : <div key={"key"}
            id={"keyI"}
            className='pointer-hand'>
            Not found
          </div>
        }
      </div>
      <div id="hierarchy-container"
        className='no-text-select mb-3'
      >
      </div>

      <Details
        hlevel="1"
        type="paragraph"
        tag="p"
        description="This is a paragraph."
      />

    </div>
  )
}

export default HierarchyTree